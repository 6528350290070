import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'formatDatePipe'
})
export class FormatDatePipe implements PipeTransform {

  transform(input: any, format: string): string {
    let datePipe = new DatePipe('en-US');//TODO
    let output: string;
    if (typeof (input) == 'string') {
      input = input.replace('/Date(', '').replace(')/', '');
      if (format == undefined || format == '') {
        output = moment(input, 'DD/MM/YYYY hh:mm:ss a').format();
      } else {
        if (format.indexOf('DateLongDate') > -1) {
          output = datePipe.transform(input, 'longDate');
        } else {
          if (format.indexOf('DateShortDate') > -1) {
            output = datePipe.transform(input, 'shortDate');
          } else {
            if (format.indexOf('DateShort') > -1) {
              output = datePipe.transform(input, 'short');
            } else {
              if (format.indexOf('DateMedium') > -1) {
                output = datePipe.transform(input, 'medium');
              } else {
                if (format.indexOf('DateFullDate') > -1) {
                  output = datePipe.transform(input, 'fullDate');
                } else {
                  if (format.indexOf('DateShortTime') > -1) {
                    output = datePipe.transform(input, 'shortTime');
                  } else {
                    if (format.indexOf('DateMediumTime') > -1) {
                      output = datePipe.transform(input, 'mediumTime');
                    } else {
                      if (format.indexOf('DateMediumDate') > -1) {
                        output = datePipe.transform(input, 'mediumDate');
                      } else {
                        output = datePipe.transform(input);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    } else {
      if (typeof (input) == 'object') {
        if (format.indexOf('WeekOfYear') > -1) {
          output = this.getWeekNumber(input).toString();
        } else {
          output = datePipe.transform(input);
        }
      } else {
        output = input;
      }
    }
    if(output == 'Invalid date'){
      output = datePipe.transform(input);
    }
    return output;
  }

  private getWeekNumber(date: Date): number {
    // Copy date so don't modify original
    let d = new Date(date);
    //d.setHours(0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    let yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    let weekNo = Math.ceil((((d.valueOf() - yearStart.valueOf()) / 86400000) + 1) / 7);
    // Return array of year and week number
    return weekNo;
  }

}
