import { NgModule } from '@angular/core';
import { FormatDataPipe } from './pipes/format-data.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { LongPressDirective } from './long-press.directive';

@NgModule({
  imports: [
  ],
  declarations: [
    FormatDataPipe, FormatDatePipe, SanitizeHtmlPipe,LongPressDirective
  ],
  providers: [
  ],
  exports: [
    FormatDataPipe, FormatDatePipe, SanitizeHtmlPipe,LongPressDirective
  ]
})
export class SharedModule { }