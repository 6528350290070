import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SQLiteObject, SQLite } from '@ionic-native/sqlite/ngx';

@Injectable({ providedIn: 'root' })
export class DBProviderService {

  constructor(public platform: Platform, private sqlite: SQLite) { }
  cacheDB: any = {};
  dbName: string = 'BW2DB';
  tables: any = {
    bw2_settings: [
      { name: 'device_guid', type: 'text' },
      { name: 'portal_guid', type: 'text' },
      { name: 'portal_description', type: 'text' },
      { name: 'last_portal', type: 'text' },
      { name: 'translations', type: 'text' },
    ],
    mobile_settings:[
      { name: 'authentication_type', type: 'text' }
    ]
  };

  public init(): Promise<any> {
    this.cacheDB = {};
    return new Promise((resolve, reject) => {
      this.selectAllFromTable('mobile_settings').then(result => {
        if(this.tables.mobile_settings && this.tables.mobile_settings.length>0)
        {
          let numColumns=this.tables.mobile_settings.length;
          for(let i=0;i < numColumns;i++)
          {
            this.verifyAndAddColumn(this.tables.mobile_settings[i],'mobile_settings').then(result=>{
              if(i==numColumns-1)
              {
                this.manageBw2Settings().then(result=>{
                  resolve(true);
                },error=>{
                  reject(error);
                });
              }
            }).catch(error=>{
              console.log(error);
            });
          }
        }
      }, error => {
        console.error(`table mobile_settings not exists ${error}`);
        this.dropAndCreateMobileSettingsTable().then(res => {
          this.manageBw2Settings().then(result=>{
            resolve(true);
          },error=>{
            reject(error);
          });
        }, error => {
          reject(error);
        });
      }); 
    });
  }

  public manageBw2Settings(): Promise<any> {
    this.cacheDB = {};
    return new Promise((resolve, reject) => {
      this.selectAllFromTable('bw2_settings').then(result => {
        console.log(`table bw2_settings exists ${result}`);
        if(this.tables.bw2_settings && this.tables.bw2_settings.length>0)
        {
          let numColumns=this.tables.bw2_settings.length;
          for(let i=0;i < numColumns;i++)
          {
            this.verifyAndAddColumn(this.tables.bw2_settings[i],'bw2_settings').then(result=>{
              if(i==numColumns-1)
              {
                resolve(true);
              }
            }).catch(error=>{
              console.log(error);
            });
          }
        }
      }, error => {
        console.error(`table bw2_settings not exists ${error}`);
        this.dropAndCreateBw2SettingsTable().then(res => {
          resolve(true);
        }, error => {
          reject(error);
        });
      }); 
    });
  }

  private verifyAndAddColumn(column:any,tablename:any): Promise<any>{
    let selectColumn = 'select '+column.name+' from '+tablename;
    return new Promise((resolve, reject) => {
      this.executeQuery(selectColumn).then(result => {
        console.log("column "+ column.name +" exists in "+tablename);
        resolve(this.fetchAll(result));
      }).catch(error => {
        console.log("column "+ column.name +" not exist in"+tablename);
        let query = 'ALTER TABLE '+tablename+' ADD COLUMN '+column.name + ' ' + column.type;
        this.executeQuery(query).then((res) => {
          console.log("column "+ column.name +" added in "+tablename);
          resolve(true);
        }, (error) => {
          reject(error);
        });
      });
    });
  }

  private dropAndCreateMobileSettingsTable(): Promise<any> {
    return new Promise((resolve, reject) => {
      let columns = [];
      this.tables.mobile_settings.forEach((column) => {
        columns.push(column.name + ' ' + column.type)
      });
      let query = 'DROP TABLE IF EXISTS mobile_settings';
      this.executeQuery(query).then((res) => {
        query = 'CREATE TABLE mobile_settings (' + columns.join(',') + ')';
        this.executeQuery(query).then((res) => {
          console.log(res);
          resolve(true);
        }, (error) => {
          reject(error);
        });
      }, (error) => {
        reject(error);
      });
    });
  }

  private dropAndCreateBw2SettingsTable(): Promise<any> {
    return new Promise((resolve, reject) => {
      let columns = [];
      this.tables.bw2_settings.forEach((column) => {
        columns.push(column.name + ' ' + column.type)
      });
      let query = 'DROP TABLE IF EXISTS bw2_settings';
      this.executeQuery(query).then((res) => {
        query = 'CREATE TABLE bw2_settings (' + columns.join(',') + ')';
        this.executeQuery(query).then((res) => {
          console.log(res);
          resolve(true);
        }, (error) => {
          reject(error);
        });
      }, (error) => {
        reject(error);
      });
    });
  }

  public selectAllFromTable(tableName:any): Promise<any> {
    let query = 'select * from '+tableName;
    return new Promise((resolve, reject) => {
      this.executeQuery(query).then(result => {
        resolve(this.fetchAll(result));
      }).catch(error => {
        reject(error);
      });
    });
  }

  public findSettingByPortalGuid(portal_guid:any): Promise<any> {
    let query = 'select * from bw2_settings where portal_guid = ?';
    return new Promise((resolve, reject) => {
      this.executeQuery(query,[portal_guid]).then(result => {
        resolve(this.fetchAll(result));
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updatePortalDescriptionAndTranslationsByPortalGuid(portal_description:any,translationsJson:any,portal_guid:any): Promise<any> {
    let translations=JSON.stringify(translationsJson);
    let query = 'UPDATE bw2_settings SET portal_description = ?,translations = ? where portal_guid = ?';
    return new Promise((resolve, reject) => {
      this.executeQuery(query,[portal_description,translations,portal_guid]).then(result => {
        resolve(this.fetchAll(result));
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateAllSettings(): Promise<any> {
    let query = 'UPDATE bw2_settings SET last_portal=?';
    return new Promise((resolve, reject) => {
      this.executeQuery(query,["0"]).then(result => {
        resolve(this.fetchAll(result));
      }).catch(error => {
        reject(error);
      });
    });
  }

  public updateAuthenticationType(authenticationType:any): Promise<any> {
    let query = 'UPDATE mobile_settings SET authentication_type=?';
    return new Promise((resolve, reject) => {
      this.executeQuery(query,[authenticationType]).then(result => {
        resolve(this.fetchAll(result));
      }).catch(error => {
        reject(error);
      });
    });
  }

  
  public insertMobileSettings(): Promise<any> {
      let query = 'INSERT INTO mobile_settings (authentication_type) VALUES (?)';
      return this.executeQuery(query,['password']);
  }

  public insertOrUpdateSetting(device_guid: string, portal_guid: string): Promise<any> {
    return this.findSettingByPortalGuid(portal_guid).then(res => {
      if(res.length==0)
      {
        let query = 'INSERT INTO bw2_settings (device_guid, portal_guid,last_portal) VALUES (?,?,?)';
        return this.executeQuery(query, [device_guid, portal_guid,"1"]);
      }else{
        let query = 'UPDATE bw2_settings SET device_guid=?,last_portal=? where portal_guid = ?';
        return  this.executeQuery(query,[device_guid,"1",portal_guid]);
      }
    }).catch(ex => {
      console.error(ex);
    });
  }

  public executeQuery(query: string, params?: any[]): Promise<any> {// call only this.platform.is('android') || this.platform.is('ios')) && !this.platform.is('mobileweb')
    params = typeof params !== 'undefined' ? params : [];
    let loc: any = 1;
    if (this.platform.is('ios')) {
      loc = 'default';
    }
    return new Promise((resolve, reject) => {
      this.sqlite.create({ name: this.dbName, location: loc }).then(
        (db: SQLiteObject) => {
          db.transaction((tx) =>
            tx.executeSql(query, params, (tx, res) => {
              resolve(res);
            }, (tx, err) => {
              reject(err);
            })
          );
        }, (err) => {
          console.error(err);
          reject(err);
        }
      ).catch(ex => {
        console.error(ex);
      });
    });
  }

  public fetchAll(result): any[] {
    let output = [];
    try {
      for (let i = 0; i < result.rows.length; i++) {
        output.push(result.rows.item(i));
      }
    } catch (ex) {
    }
    return output;
  }

}
