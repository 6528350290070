import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  //{ path: '', redirectTo: 'ipm/ip', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./init/init.module').then(m => m.InitPageModule) },
  { path: 'lpm', loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule) },
  { path: 'mpm', loadChildren: () => import('./main/main.module').then(m => m.MainPageModule) },
  { path: 'epm', loadChildren: () => import('./error/error.module').then(m => m.ErrorPageModule) },
  { path: '**', redirectTo: 'epm/ep' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    relativeLinkResolution: 'corrected'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
